import React from "react";
import { QRCode } from 'react-qrcode-logo';
import { createStyles, Text } from '@mantine/core';

const useStyles = createStyles((theme) => ({
    page: {
        [`@media all`]: {
            display: 'none',
        },
        [`@media print`]: {
            display: 'block',
            pageBreakAfter: 'always',
        },
    },
    column : {
        display: 'flex',
        flexDirection: 'row'
    },
    container : {
        columnCount: 2,
        // height: '100%',
        // paddingTop: 100,
        // marginTop: 0
        margin: 0,
        padding: 0
    }
  }));

  const links = {
    "6363fbe1c633158b369700ad": "https://qr.chinggismuseum.com/post/",
    "636a2a97d2c55fda58f9cde3": "https://umnugovi.zevcommunity.org/post/",
    "6376f10ee0851ee207c8b004": "https://qr.ndaatgal.mn/post/",
    "63846b1a62077385234bbea7": "https://qr.zevcommunity.org/post/",
    "63e080429cead592ddca5f42": "https://templemuseum.mn/post/",
    "64b5062986f7869125ed69ac": "https://qr.jargaltkhaan.zevcommunity.org/post/",
    "661c58421f90ac3bcf2ee5bb": "https://nhm.zevcommunity.org/post/",
    "664cf50fb66908441051362e": "https://mongolbank.zevcommunity.org/post/",
    "664cf51fb669084410513632": "https://astropark.zevcommunity.org/post/",
    "66afe1854232f5d7b07e615b": "https://berkh.zevcommunity.org/post/",
  }
  
  const logos = {
    "6363fbe1c633158b369700ad": require('../logo.png'),
    "636a2a97d2c55fda58f9cde3": require('../logo-umnugovi.png'),
    "6376f10ee0851ee207c8b004": require('../logo-ndsh1.png'),
    "63846b1a62077385234bbea7": require('../logo-dummy.png'),
    "63e080429cead592ddca5f42": require('../logo_mn.png'),
    "64b5062986f7869125ed69ac": require('../logo-jargal.jpg'),
    "661c58421f90ac3bcf2ee5bb": require('../logo-nhm.jpeg'),
    "664cf50fb66908441051362e": require('../logo-mongolbank.png'),
    "664cf51fb669084410513632": require('../logo-astro.png'),
    "66afe1854232f5d7b07e615b": require('../logo-berkh.png'),
  }

  const fgColors = {
    "6363fbe1c633158b369700ad": "#000000",
    "636a2a97d2c55fda58f9cde3": "#000000",
    "6376f10ee0851ee207c8b004": '#E5AA2D',
    "63846b1a62077385234bbea7": "#000000",
    "63e080429cead592ddca5f42": "#000000",
    "661c58421f90ac3bcf2ee5bb": "#000000",
    "664cf50fb66908441051362e": "#000000",
    "664cf51fb669084410513632": "#000000",
    "66afe1854232f5d7b07e615b": "#000000",
  }


  const bgColors = {
    "6363fbe1c633158b369700ad": "#FFFFFF",
    "636a2a97d2c55fda58f9cde3": "#FFFFFF",
    "6376f10ee0851ee207c8b004": "#00674D",
    "63846b1a62077385234bbea7": "#FFFFFF",
    "63e080429cead592ddca5f42": "#FFFFFF",
    "661c58421f90ac3bcf2ee5bb": "#FFFFFF",
    "664cf50fb66908441051362e": "#FFFFFF",
    "664cf51fb669084410513632": "#FFFFFF",
    "66afe1854232f5d7b07e615b": "#FFFFFF"
  }

const BlackQr = React.forwardRef((props, ref) => {
    const { classes } = useStyles();
    
    return  (
        <div ref={ref} className={classes.container}>
            {
                props.data.map((d, index) => {
                return(
                    <>                        
                        {/* <div style={{display: 'flex', alignItems:'center', justifyContent:'center', flexDirection:'column',}}> */}
                            {/* <br/> */}
                            {/* <br/> */}
                            {/* <br/> */}
                            {/* <br/> */}
                            {/* <Text style={{fontSize: 20, fontWeight: 'bold', color:"#000"}}>{d.name}</Text> */}
                            {/* <br/> */}
                            <QRCode 
                            value={ links[d.museum_id] + d._id.toString() }
                            logoImage={logos[d.museum_id]}
                            removeQrCodeBehindLogo
                            // qrStyle={'squares'}
                            // logoWidth={46}
                            // bgColor="#ffffff"
                            // fgColor="#000000"
                            // eyeRadius={1}
                            // ecLevel='L'
                            // quietZone={10}
                            // size={200}
                            qrStyle={'dots'}
                            logoWidth={88.19}
                            size={264.57}
                            // logoWidth={121.19}
                            // size={364.57}
                            bgColor={bgColors[d.museum_id]}
                            fgColor={fgColors[d.museum_id]}
                            eyeRadius={10}
                            ecLevel='H'
                            quietZone={100}
                            />    
                        {/* </div> */}
                        {/* <div className={classes.page} /> */}
                    </>
                )}
            )}

        </div>
    )
})
export default BlackQr;
